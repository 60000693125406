import { Grid } from '@northstar/core';
import { useServiceRequest } from 'hooks/useServiceRequest';
import { Suspense, useState } from 'react';
import {
    AnswerOption,
    LookupResponse,
    CaseQuestionsAnswers,
    QuestionCriteria,
} from 'types/createCase';
import { objectOrderBy } from 'utils/objectOrderBy';
import { DynamicFormField } from 'types/formFields';
import { useFormContext } from 'react-hook-form';
import { FormFieldLoading } from 'components/FormFieldLoading';
import {
    LazyFormAutocomplete,
    LazyFormDateTimePicker,
    LazyFormInput,
} from 'components/FormFields';
import { getQuestionAnswerReqData } from '../getQuestionAnswerReqData';
import { conditionalValidationFields } from '../validationSchema';
import { removeDescendantFields } from './removeDescendantFields';
import { createFieldState } from './createFieldState';
import { createValidationRules } from './createValidationRules';
import { getFieldStateOptions } from './getFieldStateOptions';
import { getIdToCompare } from './getIdToCompare';
import { getRelevantCriteria } from './getRelevantCriteria';
import { technicalSupportRequestId } from './technicalSupportRequestId';
import { getChainedFields } from './getChainedFields';
import { useDraftForServiceRequest } from './useDraftForServiceRequest';
import { conditionalFieldsIds } from '../ConditionalFormFields';

type Props = {
    defaultSeverityLevel: string;
    dynamicFormFields: DynamicFormField[];
    handleDraftChange: (data: any) => void;
    initialDynamicFields: CaseQuestionsAnswers[];
    lookUpOptions: LookupResponse[];
    productBrandId: string | undefined;
    saveQAResponses: (data: CaseQuestionsAnswers[]) => void;
    setDynamicFormFields: (fields: DynamicFormField[]) => void;
    setLookUpOptions: (options: LookupResponse[]) => void;
    setShowConditionalFields: (value: boolean) => void;
    updateFormValidation: (removedFields: any, validation: any) => void;
    triggerDraftSave: (updatedValues: any) => void;
};

const DynamicFormFields = ({
    defaultSeverityLevel,
    dynamicFormFields,
    handleDraftChange,
    initialDynamicFields,
    lookUpOptions,
    productBrandId,
    saveQAResponses,
    setDynamicFormFields,
    setLookUpOptions,
    setShowConditionalFields,
    updateFormValidation,
    triggerDraftSave,
}: Props) => {
    const { questionCriteria, questionDefinitions, answerOptions } =
        useServiceRequest();
    const [loadingField, setLoadingField] = useState(false);

    const {
        getValues: getFormValues,
        setValue: setFieldValue,
        unregister,
        reset: restFormValues,
        trigger,
    } = useFormContext();

    const formValues = getFormValues();

    const removeUnusedFormValues = (
        removedFieldIds: string[],
        fields: DynamicFormField<AnswerOption>[]
    ) => {
        const newValues = fields.map(({ props: { name } }) => name);

        removedFieldIds.forEach((key) => {
            if (!newValues.includes(key)) {
                unregister(key);
            }
        });
    };

    const createNewFormFields = async (
        relevantCriteria: QuestionCriteria[]
    ) => {
        const formFieldsPromises = relevantCriteria.map(async (qCItem) => {
            const qDefinition = (questionDefinitions || []).find(
                (qDItem) => qDItem.id === qCItem.questionId
            );

            if (!qDefinition) return undefined;

            const fieldState = createFieldState(qCItem, qDefinition);

            if (fieldState.elementType === 'dropDown') {
                if (!lookUpOptions.length && qDefinition.lookupSource) {
                    setLoadingField(true);
                }

                const { fieldStateOptions, lookUpResponse } =
                    await getFieldStateOptions({
                        answerOptions,
                        definition: qDefinition,
                        lookUpOptions,
                        productBrandId,
                        questionId: qCItem.questionId,
                        selectedAccount: formValues.selectedAccount,
                    });

                fieldState.props.options = fieldStateOptions;

                if (lookUpResponse) {
                    setLookUpOptions(lookUpResponse);
                }

                setLoadingField(false);
            }

            return fieldState;
        });

        return (await Promise.all(formFieldsPromises)).filter(
            Boolean
        ) as DynamicFormField<AnswerOption>[];
    };

    const processNonTechnicalSupportItem = async (
        selectedAnswer: AnswerOption,
        removedFieldIds: string[],
        remainingFormFields: DynamicFormField[]
    ) => {
        const idToCompare = getIdToCompare(selectedAnswer, answerOptions);
        const relevantCriteria = getRelevantCriteria(
            idToCompare,
            questionCriteria
        );

        const newFormFields = await createNewFormFields(relevantCriteria);
        const chainedFormFields = [] as DynamicFormField[];
        const newFormValues: Record<string, string> = {};

        newFormFields.forEach(({ props, defaultValue }) => {
            newFormValues[props.name] =
                formValues[props.name] || defaultValue || '';

            if (!formValues[props.name]) return;

            const optItem = {
                id: formValues[props.name],
                questionId: props.name,
            } as AnswerOption;

            const chained = getChainedFields({
                startOption: optItem,
                dynamicFormFields,
                formValues,
            });

            chainedFormFields.push(...chained);
        });

        const newValidation = createValidationRules(newFormFields);

        const orderedByKey = objectOrderBy(
            [...remainingFormFields, ...newFormFields, ...chainedFormFields],
            'displayOrder'
        );

        const updatedValues = {
            [selectedAnswer.questionId]: selectedAnswer.id,
            ...newFormValues,
        };

        setDynamicFormFields(orderedByKey);
        setShowConditionalFields(false);
        restFormValues(newFormValues, { keepValues: true });
        updateFormValidation(
            [...removedFieldIds, ...conditionalFieldsIds],
            newValidation
        );
        removeUnusedFormValues(removedFieldIds, newFormFields);
        triggerDraftSave({
            updatedValues,
            removedFieldIds,
            formFields: orderedByKey,
        });
    };

    const processTechnicalSupportItem = (
        removedFieldIds: string[],
        newFormFields: DynamicFormField[],
        selectedAnswer: AnswerOption
    ) => {
        updateFormValidation(removedFieldIds, conditionalValidationFields);
        removeUnusedFormValues(removedFieldIds, newFormFields);
        setDynamicFormFields(newFormFields);
        setShowConditionalFields(true);

        const updatedValues = {
            [selectedAnswer.questionId]: selectedAnswer.id,
        };

        triggerDraftSave({
            updatedValues,
            removedFieldIds,
            formFields: newFormFields,
        });
    };

    const handleDynamicFiledClear = (questionId: string) => {
        const updatedValues = {
            [questionId]: '',
        };

        const { remainingFormFields, removedFieldIds } = removeDescendantFields(
            dynamicFormFields,
            questionId
        );

        setDynamicFormFields(remainingFormFields);
        removeUnusedFormValues(removedFieldIds, remainingFormFields);

        if (questionId !== technicalSupportRequestId) {
            updateFormValidation(removedFieldIds, conditionalValidationFields);
        }

        triggerDraftSave({
            updatedValues,
            removedFieldIds,
            formFields: remainingFormFields,
        });
    };

    const handleDynamicFieldSelect = ({
        data,
        name,
    }: {
        data: AnswerOption | null;
        name: string;
    }) => {
        if ((!data && name) || !data?.id) {
            handleDynamicFiledClear(name);

            return;
        }

        const { remainingFormFields, removedFieldIds } = removeDescendantFields(
            dynamicFormFields,
            data.questionId
        );

        if (data.id === technicalSupportRequestId) {
            processTechnicalSupportItem(
                removedFieldIds,
                remainingFormFields,
                data
            );
            return;
        }

        processNonTechnicalSupportItem(
            data,
            removedFieldIds,
            remainingFormFields
        );

        setFieldValue('severity', defaultSeverityLevel);
        setFieldValue('operatingSystem', null);
        setFieldValue('productVersion', null);
    };

    const handleTextFieldChange = async ({
        name,
        value,
    }: {
        name: string;
        value: string;
    }) => {
        const isValid = await trigger(name);

        if (value && !isValid) return;

        const selectedValue = {
            [name]: value,
        };

        const caseQuestionAnswerRequests = getQuestionAnswerReqData({
            answerOptions,
            formValues: selectedValue,
            lookUpOptions,
            questionCriteria,
            questionDefinitions,
            formFields: dynamicFormFields,
        });

        saveQAResponses(caseQuestionAnswerRequests);

        handleDraftChange({});
    };

    useDraftForServiceRequest({
        initialDynamicFields,
        lookUpOptions,
        productBrandId,
        setDynamicFormFields,
        setLookUpOptions,
        updateFormValidation,
        setShowConditionalFields,
        saveQAResponses,
    });

    if (dynamicFormFields.length === 0) return null;

    const renderField = (field: DynamicFormField) => {
        const { elementType, props } = field;

        switch (elementType) {
            case 'input':
                return (
                    <Suspense fallback={<FormFieldLoading />}>
                        <LazyFormInput
                            handleOnChange={handleTextFieldChange}
                            {...props}
                        />
                    </Suspense>
                );
            case 'dropDown':
                return (
                    <Suspense fallback={<FormFieldLoading />}>
                        <LazyFormAutocomplete
                            {...props}
                            handleOnChange={handleDynamicFieldSelect}
                        />
                    </Suspense>
                );
            case 'date':
            case 'time':
            case 'dateTime':
                return (
                    <Suspense fallback={<FormFieldLoading />}>
                        <LazyFormDateTimePicker
                            {...props}
                            handleOnChange={handleTextFieldChange}
                            elementType={elementType}
                        />
                    </Suspense>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <>
                {dynamicFormFields.map((item) => (
                    <Grid key={item.props.name} item xs={12}>
                        {renderField(item)}
                    </Grid>
                ))}
            </>
            {loadingField && (
                <Grid item xs={12}>
                    <FormFieldLoading />
                </Grid>
            )}
        </>
    );
};

export { DynamicFormFields };
