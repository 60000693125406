import { Grid } from '@northstar/core';
import { Suspense, useEffect, useRef, useState } from 'react';
import { ProductPermission } from 'types/permissions';
import { objectOrderBy } from 'utils/objectOrderBy';
import {
    AnswerOption,
    LookupResponse,
    CaseQuestionsAnswers,
    QuestionDefinition,
} from 'types/createCase';
import { useFormContext } from 'react-hook-form';

import { useServiceRequest } from 'hooks/useServiceRequest';
import { DynamicFormField } from 'types/formFields';
import { LazyFormAutocomplete } from 'components/FormFields';
import { FormFieldLoading } from 'components/FormFieldLoading';
import { removeDescendantFields } from 'pages/Support/components/Create/DynamicFormFields/removeDescendantFields';
import { DynamicFormFields } from '../DynamicFormFields';
import { getLookUpResponse } from '../DynamicFormFields/getLookUpResponse';
import { getProductMatchCriteria } from '../DynamicFormFields/getProductMatchCriteria';
import { createFieldState } from '../DynamicFormFields/createFieldState';
import { createValidationRules } from '../DynamicFormFields/createValidationRules';
import { conditionalValidationFields } from '../validationSchema';
import { getChainedFields } from '../DynamicFormFields/getChainedFields';
import { conditionalFieldsIds } from '../ConditionalFormFields';
import { getQuestionAnswerReqData } from '../getQuestionAnswerReqData';

type Props = {
    defaultSeverityLevel: string;
    handleDraftChange: (data: any) => void;
    initialDynamicFields: CaseQuestionsAnswers[];
    lookUpOptions: LookupResponse[];
    productBrandId: string | undefined;
    saveQAResponses: (
        data: CaseQuestionsAnswers[],
        removedFieldIds?: string[]
    ) => void;
    selectedUserProducts: ProductPermission[] | undefined;
    setLookUpOptions: (options: LookupResponse[]) => void;
    setShowConditionalFields: (value: boolean) => void;
    updateFormValidation: (removedFields: any, validation: any) => void;
};

const ProductSelection = ({
    defaultSeverityLevel,
    handleDraftChange,
    initialDynamicFields,
    lookUpOptions,
    productBrandId,
    saveQAResponses,
    selectedUserProducts,
    setLookUpOptions,
    setShowConditionalFields,
    updateFormValidation,
}: Props) => {
    const {
        getValues: getFormValues,
        setValue: setFieldValue,
        unregister,
        watch,
    } = useFormContext();

    const formValues = getFormValues();

    const accountChangeWatcher = watch('selectedAccount');

    const { questionCriteria, questionDefinitions, answerOptions } =
        useServiceRequest();

    const [dynamicFormFields, setDynamicFormFields] = useState<
        DynamicFormField[]
    >([]);

    const productRef = useRef<HTMLElement>(null);

    const cleanDynamicFieldsStates = () => {
        const fieldNames = [] as string[];

        if (dynamicFormFields.length === 0) return;

        dynamicFormFields.forEach((item) => {
            fieldNames.push(item.props.name);
            unregister(item.props.name);
        });

        setDynamicFormFields([]);
        saveQAResponses([]);
        setShowConditionalFields(true);
        updateFormValidation(fieldNames, conditionalValidationFields);
        setLookUpOptions([]);
    };

    const triggerDraftSave = ({
        updatedValues,
        removedFieldIds,
        productValue = {},
        formFields = dynamicFormFields,
    }: any) => {
        const caseQuestionAnswerRequests = getQuestionAnswerReqData({
            answerOptions,
            formValues: updatedValues,
            lookUpOptions,
            questionCriteria,
            questionDefinitions,
            formFields,
        });

        saveQAResponses(caseQuestionAnswerRequests, removedFieldIds);
        handleDraftChange(productValue);
    };

    const findLookupDefinition = (fields: DynamicFormField[]) =>
        (questionDefinitions || []).find((qD) =>
            fields.some((item) => item.props.name === qD.id && qD.lookupSource)
        );

    const fetchLookupResponse = async (
        lookupDefinition: QuestionDefinition,
        value: ProductPermission,
        selectedAccount: string
    ) => {
        const response = await getLookUpResponse({
            lookUpName: lookupDefinition.lookupSource || '',
            productBrandId: value.brandId || '',
            selectedAccount,
        });

        return response.map((item) => ({
            ...item,
            questionId: lookupDefinition.id,
        }));
    };

    const updateFieldsWithLookupResponse = (
        fields: DynamicFormField[],
        lookupDefinition: QuestionDefinition,
        lookUpResponse: LookupResponse[]
    ) => {
        const lookupFieldIndex = fields.findIndex(
            (field) => field.props.name === lookupDefinition.id
        );

        if (lookupFieldIndex === -1) {
            return { updatedFields: fields, newFormValues: {} };
        }

        const lookupField = fields[lookupFieldIndex];
        const isLookupSelected = lookUpResponse.some(
            (item) => item.id === formValues[lookupField.props.name]
        );

        const updateDropdownField = (formFields: DynamicFormField[]) => {
            if (lookupField.elementType !== 'dropDown') return formFields;

            const updatedFields = [...formFields];
            updatedFields[lookupFieldIndex] = {
                ...lookupField,
                props: {
                    ...lookupField.props,
                    options: objectOrderBy(lookUpResponse, 'name'),
                },
            };

            return updatedFields;
        };

        if (isLookupSelected) {
            return {
                updatedFields: updateDropdownField(fields),
                newFormValues: {},
            };
        }

        const { remainingFormFields, removedFieldIds } = removeDescendantFields(
            dynamicFormFields,
            lookupDefinition.id
        );

        const updatedFields = updateDropdownField(remainingFormFields);

        const newFormValues: Record<string, string> = updatedFields.reduce(
            (values, field) => ({
                ...values,
                [field.props.name]:
                    formValues[field.props.name] || field.defaultValue || '',
            }),
            {} as Record<string, string>
        );

        if (formValues[lookupDefinition.id] !== '') {
            newFormValues[lookupDefinition.id] = '';
            setFieldValue(lookupDefinition.id, '');
        }

        updateFormValidation(removedFieldIds, {});

        return { updatedFields, newFormValues, removedFieldIds };
    };

    const processLookupForProduct = async (
        fields: DynamicFormField[],
        value: ProductPermission | null
    ) => {
        if (!(lookUpOptions.length > 0 && questionDefinitions && value))
            return {
                newFormValues: {},
            };

        const lookupDefinition = findLookupDefinition(fields);

        if (!lookupDefinition) {
            setDynamicFormFields(fields);

            return {
                newFormValues: {},
            };
        }

        const lookUpResponse = await fetchLookupResponse(
            lookupDefinition,
            value,
            formValues.selectedAccount
        );

        const { updatedFields, newFormValues, removedFieldIds } =
            updateFieldsWithLookupResponse(
                fields,
                lookupDefinition,
                lookUpResponse
            );

        const sortedFormFields = objectOrderBy(updatedFields, 'displayOrder');

        setDynamicFormFields(sortedFormFields);
        setLookUpOptions(lookUpResponse);

        return { newFormValues, removedFieldIds };
    };

    const processProductValue = async (value: ProductPermission | null) => {
        if (questionCriteria && questionDefinitions && answerOptions && value) {
            const criteria = getProductMatchCriteria(value, questionCriteria);

            if (!criteria) {
                cleanDynamicFieldsStates();
                return null;
            }

            const definition = questionDefinitions.find(
                (qDItem) => qDItem.id === criteria.questionId
            );

            if (!definition) {
                cleanDynamicFieldsStates();
                return null;
            }

            const updatedFormFields = [] as DynamicFormField[];
            const fieldState = createFieldState(criteria, definition);

            if (fieldState.elementType === 'dropDown') {
                const matchingAnswer = answerOptions.filter(
                    (aOItem) => aOItem.questionId === definition.id
                );

                fieldState.props.options = objectOrderBy(
                    matchingAnswer,
                    'name'
                );
            }

            updatedFormFields.push(fieldState);

            conditionalFieldsIds.forEach((fieldId) => {
                setFieldValue(fieldId, '');
            });

            const newValidation = createValidationRules([fieldState]);

            setShowConditionalFields(false);
            updateFormValidation(conditionalFieldsIds, newValidation);

            if (formValues[criteria.questionId]) {
                const startOption = {
                    id: formValues[criteria.questionId],
                    questionId: criteria.questionId,
                } as AnswerOption;

                const dependentFields = getChainedFields({
                    startOption,
                    dynamicFormFields,
                    formValues,
                });

                const { newFormValues, removedFieldIds } =
                    await processLookupForProduct(
                        [fieldState, ...dependentFields],
                        value
                    );

                return {
                    draftValues: { ...formValues, newFormValues },
                    removedFieldIds,
                };
            }

            setFieldValue(fieldState.props.name, definition.defaultValue || '');

            const sortedFormFields = objectOrderBy(
                updatedFormFields,
                'displayOrder'
            );

            setDynamicFormFields(sortedFormFields);

            const draftFormValues = {
                [fieldState.props.name]: definition.defaultValue || '',
            };

            return {
                draftValues: draftFormValues,
            };
        }

        return null;
    };

    const handleProductChange = async ({
        data,
    }: {
        name: string;
        data: ProductPermission | null;
    }) => {
        const processResult = await processProductValue(data);

        if (!data || !processResult) {
            cleanDynamicFieldsStates();
            handleDraftChange({ name: 'productId', value: data });
            return;
        }

        const productValue = {
            name: 'productId',
            value: data,
        };

        if (processResult) {
            triggerDraftSave({
                updatedValues: processResult.draftValues,
                productValue,
                removedFieldIds: processResult.removedFieldIds,
            });
        }
    };

    useEffect(() => {
        cleanDynamicFieldsStates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountChangeWatcher]);

    return (
        <>
            <Grid item xs={12}>
                <Suspense fallback={<FormFieldLoading />}>
                    <LazyFormAutocomplete
                        handleOnChange={handleProductChange}
                        fieldRef={productRef}
                        inputLabel="Product"
                        labelKey="productName"
                        name="productId"
                        options={selectedUserProducts}
                        placeholder="Select Product *"
                        valueKey="softwareProductId"
                    />
                </Suspense>
            </Grid>
            <DynamicFormFields
                defaultSeverityLevel={defaultSeverityLevel}
                dynamicFormFields={dynamicFormFields}
                handleDraftChange={handleDraftChange}
                initialDynamicFields={initialDynamicFields}
                lookUpOptions={lookUpOptions}
                productBrandId={productBrandId}
                saveQAResponses={saveQAResponses}
                setDynamicFormFields={setDynamicFormFields}
                setLookUpOptions={setLookUpOptions}
                setShowConditionalFields={setShowConditionalFields}
                triggerDraftSave={triggerDraftSave}
                updateFormValidation={updateFormValidation}
            />
        </>
    );
};

export { ProductSelection };
