import {
    AnswerOption,
    LookupResponse,
    QuestionDefinition,
} from 'types/createCase';
import { objectOrderBy } from 'utils/objectOrderBy';
import { getLookUpResponse } from './getLookUpResponse';

type Props = {
    answerOptions: AnswerOption[] | undefined;
    definition: QuestionDefinition;
    lookUpOptions: LookupResponse[];
    productBrandId: string | undefined;
    questionId: string;
    selectedAccount: string;
};

const getFieldStateOptions = async ({
    answerOptions,
    definition,
    lookUpOptions,
    productBrandId,
    questionId,
    selectedAccount,
}: Props) => {
    if (definition.lookupSource) {
        if (lookUpOptions.length > 0)
            return {
                fieldStateOptions: lookUpOptions,
            };

        const lookUpResponse = await getLookUpResponse({
            lookUpName: definition.lookupSource,
            productBrandId,
            selectedAccount,
        });

        const modifiedQuestionId = (lookUpResponse || []).map((item) => ({
            ...item,
            questionId,
        }));

        const orderedByName = objectOrderBy(modifiedQuestionId, 'name');

        return {
            fieldStateOptions: objectOrderBy(orderedByName, 'name'),
            lookUpResponse: modifiedQuestionId,
        };
    }

    const filterById = (answerOptions || []).filter(
        (aOItem) => aOItem.questionId === definition.id
    );

    return {
        fieldStateOptions: objectOrderBy(filterById, 'name'),
    };
};

export { getFieldStateOptions };
