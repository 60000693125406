import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    TextField,
    Typography,
} from '@northstar/core';
import axios from 'utils/axios';
import { Button } from 'components/Button';
import { useApp } from 'contexts/App';
import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import { useForm } from 'react-hook-form';
import useSWR from 'swr';
import { boolean, date, object, string } from 'libs/validation';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import LoadingBox from 'components/LoadingBox';
import dayjs from 'dayjs';

const AnnouncementBannerSettings = () => {
    const validationSchema = object({
        start: date()
            .nullable()
            .min(dayjs(), 'Start date cannot be in the past')
            .typeError('Invalid start date')
            .test(
                'start-required-if-end',
                'Start date is required',
                (value, { parent }) => {
                    const { end } = parent;
                    if (end && !value) {
                        return false;
                    }

                    return true;
                }
            ),
        end: date()
            .nullable()
            .typeError('Invalid end date')
            .test(
                'end-required-if-start',
                'End date is required',
                (value, { parent }) => {
                    const { start } = parent;
                    if (start && !value) {
                        return false;
                    }
                    return true;
                }
            )
            .test(
                'end-after-start',
                'End date must be later than start date',
                (value, { parent }) => {
                    const { start } = parent;
                    if (!value || !start) return true;
                    return value > start;
                }
            ),
        isReadOnly: boolean().required().default(true),
        bannerCanClose: boolean().required(),
        bannerHref: string().url().nullable(),
        bannerText: string(),
    });

    const { data } = useSWR('/banner_info');

    const { addNotification } = useApp();

    const defaultValues = {
        start: data?.announcementBannerInfo?.start
            ? dayjs(data.announcementBannerInfo.start * 1000)
            : null,
        end: data?.announcementBannerInfo?.end
            ? dayjs(data.announcementBannerInfo.end * 1000)
            : null,
        bannerText: data?.announcementBannerInfo?.text || '',
        bannerHref: data?.announcementBannerInfo?.href || '',
        bannerCanClose: data?.announcementBannerInfo?.canClose || false,
    };

    const formInstance = useForm({
        defaultValues,
        resolver: useYupValidationResolver(validationSchema as any),
        mode: 'onTouched',
        values: defaultValues,
    });

    const {
        register,
        handleSubmit,
        formState: { isSubmitting, errors },
        setValue,
        watch,
        reset,
    } = formInstance;

    const watchBannerCanClose = watch('bannerCanClose');
    const watchStart = watch('start');
    const watchEnd = watch('end');

    const updateAppSettings = async (payload: any) => {
        try {
            const body = {
                ...data,
                announcementBannerInfo: {
                    createdAt: Date.now(),
                    start: new Date(payload.start).getTime() / 1000,
                    end: new Date(payload.end).getTime() / 1000,
                    canClose: payload.bannerCanClose,
                    text: payload.bannerText,
                    href: payload.bannerHref,
                },
            };
            const res = await axios.put('/banner_info', body);
            if (res.status === 200) {
                sessionStorage.setItem('appConfig', JSON.stringify(res.data));
                addNotification({
                    message: 'Announcement updated',
                    status: 204,
                });
            }
        } catch (e: any) {
            addNotification({
                message: e?.message,
                status: e?.response?.status,
            });
        }
    };

    const onSubmit = handleSubmit(async (values: any) => {
        await updateAppSettings(values);
    });

    const handleReset = async () => {
        if (
            // eslint-disable-next-line no-alert
            window.confirm(
                'Are you sure you want to reset the form? This will clear all entered information.'
            )
        ) {
            reset({
                start: null,
                end: null,
                bannerText: '',
                bannerHref: '',
                bannerCanClose: false,
            });
            await onSubmit();
        }
    };

    if (!data) return <LoadingBox />;

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <form onSubmit={onSubmit}>
                <Grid spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h3">
                            Announcement Banner
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl error={!!errors.start} fullWidth>
                            <FormLabel>Start date</FormLabel>
                            <DateTimePicker
                                value={watchStart}
                                onChange={(newValue) => {
                                    setValue('start', newValue);
                                }}
                                minDate={dayjs()}
                                slotProps={{
                                    field: { clearable: true },
                                    textField: {
                                        helperText: errors.start?.message,
                                        FormHelperTextProps: {
                                            error: true,
                                        },
                                    },
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl error={!!errors.end} fullWidth>
                            <FormLabel>End date</FormLabel>
                            <DateTimePicker
                                value={watchEnd}
                                onChange={(newValue) => {
                                    setValue('end', newValue);
                                }}
                                minDate={dayjs()}
                                slotProps={{
                                    field: { clearable: true },
                                    textField: {
                                        helperText: errors.end?.message,
                                        FormHelperTextProps: {
                                            error: true,
                                        },
                                    },
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl error={!!errors.bannerText} fullWidth>
                            <FormLabel>Banner text</FormLabel>
                            <TextField
                                {...register('bannerText')}
                                fullWidth
                                variant="outlined"
                                error={!!errors?.bannerText}
                                helperText={
                                    errors?.bannerText?.message as string
                                }
                                multiline
                                rows="4"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl error={!!errors.bannerHref} fullWidth>
                            <FormLabel>Banner link</FormLabel>
                            <TextField
                                {...register('bannerHref')}
                                fullWidth
                                variant="outlined"
                                error={!!errors?.bannerHref}
                                helperText={
                                    errors?.bannerHref?.message as string
                                }
                                placeholder="https://example.com"
                                type="url"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    {...register('bannerCanClose')}
                                    checked={watchBannerCanClose}
                                />
                            }
                            label="Can close banner"
                            labelPlacement="end"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={isSubmitting}
                            isLoading={isSubmitting}
                        >
                            Save
                        </Button>
                        {watchStart && watchEnd && (
                            <Button
                                sx={{ marginLeft: 2 }}
                                onClick={handleReset}
                                disabled={isSubmitting}
                            >
                                Reset
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </form>
        </LocalizationProvider>
    );
};

export default AnnouncementBannerSettings;
