import { CaseQuestionsAnswers } from 'types/createCase';

type Props = {
    newData: CaseQuestionsAnswers[];
    existingData: Map<string, CaseQuestionsAnswers>;
    removedFieldIds?: string[];
};

const modifySavedQAResponses = ({
    newData,
    existingData,
    removedFieldIds,
}: Props) => {
    if (newData.length === 0) return new Map();

    removedFieldIds?.forEach((id) => {
        existingData.delete(id);
    });

    newData.forEach((item) => existingData.set(item.questionId, item));

    return existingData;
};

export { modifySavedQAResponses };
