import { CaseQuestionsAnswers } from 'types/createCase';
import { objectOrderBy } from 'utils/objectOrderBy';

const getSavedQAResponses = (data: Map<string, CaseQuestionsAnswers>) => {
    if (data.size === 0) return {};

    const list = Array.from(data.values());

    return { caseQuestionAnswerRequests: objectOrderBy(list, 'displayOrder') };
};

export { getSavedQAResponses };
