import {
    Box,
    Card,
    Container,
    Grid,
    List,
    ListItem,
    Typography,
} from '@northstar/core';
import { withAuth } from 'components/Auth';
import { Main } from 'components/layouts';
import { Link } from 'components/Link';
import { useAuth } from 'contexts/Auth';
import { Outlet, useLocation } from 'react-router-dom';

const SettingsLayout = withAuth(() => {
    const { pathname: locationPathName } = useLocation();
    const setLinkColor = (path: string) =>
        locationPathName === path ? 'textPrimary' : 'textSecondary';
    const setLinkBorderColor = (path: string) =>
        locationPathName === path ? 'primary.main' : 'transparent';
    const { user } = useAuth();

    const errorStatus = !user?.allow_banner_updates ? 403 : undefined;

    return (
        <Main errorStatus={errorStatus}>
            <Box>
                <Box
                    bgcolor="marketing.duskForest"
                    paddingTop={4}
                    paddingBottom={10.5}
                    height={224}
                    display="flex"
                    alignItems="flex-end"
                >
                    <Container>
                        <Typography
                            variant="h4"
                            component="h1"
                            gutterBottom
                            sx={{ color: 'common.white' }}
                        >
                            Admin settings
                        </Typography>
                        <Typography variant="h6" sx={{ color: 'common.white' }}>
                            Change Maintenance and Banner settings
                        </Typography>
                    </Container>
                </Box>
                <Container sx={{ marginTop: -8, marginBottom: 7.5 }}>
                    <Grid spacing={4}>
                        <Grid item xs={12} md={3}>
                            <Card sx={{ boxShadow: 3 }}>
                                <List
                                    disablePadding
                                    sx={{
                                        display: {
                                            xs: 'inline-flex',
                                            md: 'flex',
                                        },
                                        flexDirection: {
                                            xs: 'row',
                                            md: 'column',
                                        },
                                        overflow: 'auto',
                                        flexWrap: 'nowrap',
                                        width: '100%',
                                        paddingY: { xs: 3, md: 4 },
                                        paddingX: { xs: 4, md: 0 },
                                    }}
                                >
                                    <ListItem
                                        component={Link}
                                        to="/admin/settings"
                                        disableGutters
                                        sx={{
                                            marginRight: { xs: 2, md: 0 },
                                            flex: 0,
                                            paddingX: { xs: 0, md: 3 },
                                            borderLeft: {
                                                xs: 'none',
                                                md: '2px solid transparent',
                                            },
                                            borderLeftColor: {
                                                md: setLinkBorderColor(
                                                    '/admin/settings'
                                                ),
                                            },
                                        }}
                                    >
                                        <Typography
                                            component="span"
                                            variant="subtitle1"
                                            noWrap
                                            color={setLinkColor(
                                                '/admin/settings'
                                            )}
                                        >
                                            Maintenance
                                        </Typography>
                                    </ListItem>
                                    <ListItem
                                        component={Link}
                                        to="/admin/settings/announcement"
                                        disableGutters
                                        sx={{
                                            marginRight: { xs: 2, md: 0 },
                                            flex: 0,
                                            paddingX: { xs: 0, md: 3 },
                                            borderLeft: {
                                                xs: 'none',
                                                md: '2px solid transparent',
                                            },
                                            borderLeftColor: {
                                                md: setLinkBorderColor(
                                                    '/admin/settings/announcement'
                                                ),
                                            },
                                        }}
                                    >
                                        <Typography
                                            component="span"
                                            variant="subtitle1"
                                            noWrap
                                            color={setLinkColor(
                                                '/admin/settings/announcement'
                                            )}
                                        >
                                            Announcement
                                        </Typography>
                                    </ListItem>
                                </List>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Card sx={{ boxShadow: 3, padding: 4 }}>
                                <Outlet />
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Main>
    );
});

export default SettingsLayout;
