import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { LookupResponse, CaseQuestionsAnswers } from 'types/createCase';
import { DynamicFormField } from 'types/formFields';
import { useServiceRequest } from 'hooks/useServiceRequest';
import { getQuestionAnswerReqData } from '../getQuestionAnswerReqData';
import { conditionalFieldsIds } from '../ConditionalFormFields';
import { createValidationRules } from './createValidationRules';
import { constructDynamicFields } from './constructDynamicFields';

type Props = {
    initialDynamicFields: CaseQuestionsAnswers[];
    lookUpOptions: LookupResponse[];
    productBrandId: string | undefined;
    setDynamicFormFields: (fields: DynamicFormField[]) => void;
    setLookUpOptions: (options: LookupResponse[]) => void;
    saveQAResponses: (data: CaseQuestionsAnswers[]) => void;
    setShowConditionalFields: (data: boolean) => void;
    updateFormValidation: (
        removedFields: string[],
        validation: Record<string, any>
    ) => void;
};

const useDraftForServiceRequest = ({
    initialDynamicFields,
    lookUpOptions,
    productBrandId,
    saveQAResponses,
    setDynamicFormFields,
    setLookUpOptions,
    setShowConditionalFields,
    updateFormValidation,
}: Props) => {
    const isDraftProcessed = useRef(false);

    const { getValues, setValue } = useFormContext();
    const { selectedAccount } = getValues();

    const {
        answerOptions,
        isServiceRequestDataLoaded,
        questionCriteria,
        questionDefinitions,
    } = useServiceRequest();

    const processDraft = useRef(async (props: any) => {
        try {
            const { fields, formNewValues, lookUp, answerIsTechnical } =
                await constructDynamicFields(props);

            setDynamicFormFields(fields);

            Object.entries(formNewValues).forEach(([key, value]) => {
                setValue(key, value);
            });

            if (!answerIsTechnical) {
                const newValidation = createValidationRules(fields);
                setShowConditionalFields(false);

                updateFormValidation(conditionalFieldsIds, newValidation);
            }

            if (lookUp) {
                setLookUpOptions(lookUp);
            }

            const caseQuestionAnswerRequests = getQuestionAnswerReqData({
                answerOptions: props.answerOptions,
                formValues: formNewValues,
                lookUpOptions: lookUp || [],
                questionCriteria: props.questionCriteria,
                questionDefinitions: props.questionDefinitions,
                formFields: fields,
            });

            saveQAResponses(caseQuestionAnswerRequests);
        } catch (error) {
            console.error('Error processing draft:', error);
        }
    }).current;

    const shouldProcessDraft =
        isServiceRequestDataLoaded &&
        initialDynamicFields?.length > 0 &&
        productBrandId &&
        !isDraftProcessed.current;

    useEffect(() => {
        if (shouldProcessDraft) {
            isDraftProcessed.current = true;

            processDraft({
                answerOptions,
                initialDynamicFields,
                lookUpOptions,
                productBrandId,
                questionCriteria,
                questionDefinitions,
                selectedAccount,
            });
        }
    }, [
        answerOptions,
        initialDynamicFields,
        isServiceRequestDataLoaded,
        productBrandId,
        questionCriteria,
        questionDefinitions,
        shouldProcessDraft,
        processDraft,
        lookUpOptions,
        selectedAccount,
    ]);
};

export { useDraftForServiceRequest };
