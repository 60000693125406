import {
    AnswerOption,
    LookupResponse,
    CaseQuestionsAnswers,
    QuestionCriteria,
    QuestionDefinition,
} from 'types/createCase';
import { DynamicFormField } from 'types/formFields';
import { createFieldState } from './createFieldState';
import { getFieldStateOptions } from './getFieldStateOptions';
import { technicalSupportRequestId } from './technicalSupportRequestId';

type Props = {
    initialDynamicFields: CaseQuestionsAnswers[];
    answerOptions: AnswerOption[];
    questionCriteria: QuestionCriteria[];
    questionDefinitions: QuestionDefinition[];
    lookUpOptions: LookupResponse[];
    productBrandId: string;
    selectedAccount: string;
};

const constructDynamicFields = async ({
    initialDynamicFields,
    questionCriteria,
    questionDefinitions,
    answerOptions,
    lookUpOptions,
    productBrandId,
    selectedAccount,
}: Props) => {
    const formNewValues: Record<string, string> = {};

    let answerIsTechnical;
    let lookUp: LookupResponse[] | undefined;

    const formFieldsPromises = initialDynamicFields.map(async (dItem) => {
        const criteria = questionCriteria.find(
            (qCItem) => qCItem.questionId === dItem.questionId
        );

        const definition = questionDefinitions.find(
            (qDItem) => qDItem.id === dItem.questionId
        );

        if (!criteria || !definition) return null;

        const fieldState = createFieldState(criteria, definition);

        if (fieldState.elementType === 'dropDown') {
            const { fieldStateOptions, lookUpResponse } =
                await getFieldStateOptions({
                    answerOptions,
                    definition,
                    lookUpOptions,
                    productBrandId,
                    questionId: dItem.questionId,
                    selectedAccount,
                });

            if (lookUpResponse) {
                const lookUpValue = lookUpResponse.find(
                    (lOItem) => lOItem.id === dItem.answerValue
                );

                formNewValues[dItem.questionId] = lookUpValue?.id || '';

                lookUp = lookUpResponse;
            } else {
                const answerValue = answerOptions.find(
                    (aOItem) =>
                        aOItem.value === dItem.answerValue &&
                        aOItem.questionId === dItem.questionId
                );

                formNewValues[dItem.questionId] = answerValue?.id || '';

                if (answerValue?.id === technicalSupportRequestId) {
                    answerIsTechnical = true;
                }
            }

            fieldState.props.options = fieldStateOptions;
        } else {
            formNewValues[dItem.questionId] = dItem.answerText || '';
        }

        fieldState.parentQuestionId = dItem.parentQuestionId;

        return fieldState;
    });

    const isDefined = <T>(value: T | null): value is T => value !== null;

    const fields: DynamicFormField[] = (
        await Promise.all(formFieldsPromises)
    ).filter(isDefined);

    return { fields, lookUp, formNewValues, answerIsTechnical };
};

export { constructDynamicFields };
