import Button, { ButtonProps } from '@northstar/core/Button';
import { useAuth } from 'contexts/Auth';

const GuestNav = ({ sx, ...rest }: ButtonProps) => {
    const { signIn } = useAuth();

    return (
        <Button
            variant="contained"
            color="primary"
            disableElevation
            sx={{
                ...sx,
            }}
            onClick={() => signIn()}
            {...rest}
        >
            Log In
        </Button>
    );
};

export default GuestNav;
