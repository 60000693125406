import LoadingBox from 'components/LoadingBox';
import { useProvideAuth } from 'contexts/Auth/useProvideAuth';
import { useContext, createContext, ReactNode } from 'react';
import {
    SignInOptinos,
    SignOutOptinos,
    SignUpOptinos,
} from 'services/auth/AuthService';

import { Permissions } from 'types/products';

export interface SignInProps {
    email: string;
}

export type UserType = {
    sub: string;
    email_verified: boolean;
    is_portal_user: boolean;
    canCreateServiceRequests: boolean;
    user_id: string;
    name: string;
    preferred_username: string;
    accountName: string;
    accountId: string;
    given_name: string;
    avatar: string;
    allow_banner_updates: boolean;
};

export type PermissionType = {
    canKBArticles: undefined | boolean;
    canForums: undefined | boolean;
    canManuals: undefined | boolean;
    canDownloads: undefined | boolean;
};

export type AuthContextType = {
    isLoading: boolean;
    user: UserType | null;
    setUser: (user: UserType) => void;
    signOut: (options?: SignOutOptinos) => Promise<void>;
    signIn: (options?: SignInOptinos) => Promise<void>;
    signUp: (options?: SignUpOptinos) => Promise<void>;
    manageAccount: () => void;
    updateToken: (
        minValidity: number,
        successCallback?: () => void
    ) => Promise<boolean>;
    getProductPermissions: (
        permissions: Permissions | undefined,
        isSecured: boolean | undefined
    ) => PermissionType;
};

export const productPermissionsDefaultValues: PermissionType = {
    canKBArticles: undefined,
    canForums: undefined,
    canManuals: undefined,
    canDownloads: undefined,
};

export const authContextDefaultValues = {
    isLoading: false,
    user: null,
    signOut: () => Promise.resolve(),
    setUser: () => Promise.resolve(),
    signIn: () => Promise.resolve(),
    signUp: () => Promise.resolve(),
    manageAccount: () => {},
    updateToken: () => Promise.resolve(false),
    getProductPermissions: () => productPermissionsDefaultValues,
} as AuthContextType;

const authContext = createContext<AuthContextType>(authContextDefaultValues);

export function AuthProvider(props: Readonly<{ children?: ReactNode }>) {
    const auth = useProvideAuth();

    if (auth.isLoading)
        return (
            <LoadingBox
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                }}
            />
        );

    return (
        <authContext.Provider value={auth}>
            {props.children}
        </authContext.Provider>
    );
}

export const useAuth = () => {
    const contextValue = useContext(authContext);
    if (!contextValue) {
        throw new Error('useAuth must be called from within an AuthProvider');
    }
    return contextValue;
};
